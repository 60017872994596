import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlakartModalPage } from '../alakart-modal/alakart-modal.page';
import { ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { SystemService } from 'src/app/services/system.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { RouteLinkParametersService } from 'src/app/services/route-link-parameters.service';
import { ModalControllerService } from 'src/app/services/modalController.service';
import { ModalTpyes } from 'src/app/models/enums/modal-types.enum';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { IonContent, IonSlides, ModalController, Platform } from '@ionic/angular';
import { redirectModel } from 'src/app/models/redirectModel';
import { MenuTypes } from 'src/app/models/enums/menu-types.enum';
import { ActivityDto } from 'src/app/models/reservation/ActivityDto';
import { GetActivityMenusAndFoodsDto } from 'src/app/models/reservation/GetActivityMenusAndFoodsDto';
import { ActivityMenuDto } from 'src/app/models/reservation/ActivityMenuDto';
import { ActivityFoodDto } from 'src/app/models/reservation/ActivityFoodDto';
import { ToasterService } from 'src/app/services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { PdfViewerModalComponent } from '../PdfViewerModal/PdfViewerModal.component';



@Component({
    selector: 'app-reservation',
    templateUrl: './reservation.page.html',
    styleUrls: ['./reservation.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReservationPage {
    static demo; //TODO global static class oluşturulacak ve ordan çağırılacak Y.E S.Y
    customYearValues = [2020, 2016, 2008, 2004, 2000, 1996, 1990];
    customDayShortNames = ['s\u00f8n', 'man', 'tir', 'ons', 'tor', 'fre', 'l\u00f8r'];
    customPickerOptions: any;
    min;
    isDateSelected: boolean = true;
    max;
    myCounter = 60;
    kisiSayisi;
    tarihler;
    selectedPerson;
    selectedTable;
    selectedDate;
    modalData
    slideOpts = {
        initialSlide: 0,
        speed: 400
    };


    tables: Array<any> = []
    persons: Array<any> = []
    data: ActivityDto;
    availableTables: Array<any> = []
    allowSelect: boolean = false;

    downloadTimer;
    countDownFrom;
    counterCheck = 1;
    dialogRef;
    resID;
    checkInDate
    checkOutDate
    companyRecID
    activityID
    UseChildPriceAge
    ChildPriceAge
    UseDayChildReservation
    ChildReservation
    Open_Price_Control
    DayCR
    DateObjectToSend
    TableObjectToSend
    DayChildAge
    HotelId: number;
    CountDownTimer: void;
    reservasyonSegment: number = 1;
    PageChangeTime: any;
    threeDHTML: any;
    restaurantDetail: any;
    showPDF: boolean = false;
    titleOfProfile: any;
    profileTitleReservationAvailability: boolean = false;
    imgurl: any;
    ReservationId: number;
    LangID: number;
    ActivityType: number;
    KioskId
    animation: HTMLCollectionOf<Element>;
    Childs: any;
    SumPax: any;
    downloadProgress: number = 0;
    fileUrl: string;
    showPdf: boolean;
    showpdfbutton: boolean;
    showActivityMenu: boolean;
    currentMenuIndex: number = 0;
    nextMenuIndex: number = 1;
    useFoodPrice: number = 0;
    activityCurrType: string;
    totalOfAllPrice: number;
    reservationNote: string = "";
    responseActivityMenus: ActivityMenuDto[] = [];
    @ViewChild('activityMenuSlider') slides: IonSlides;
    @ViewChild('pageTop') pageTop: IonContent;
    zoom: number = 1.0; // Başlangıç zoom değeri
    constructor(
        public dialog: MatDialog,
        private routelink: RouteLinkParametersService,
        private location: Location,
        private HttpClient: HttpClient,
        private modalControllerService: ModalControllerService,
        private service: HttpService,
        private platform: Platform,
        private loading: LoadingService,
        private storageService: StorageService,
        private reservationService: ReservationService,
        public systemService: SystemService,
        private activatedRoute: ActivatedRoute,
        private toast: ToasterService,
        private translate: TranslateService,
        private domSanitazer: DomSanitizer,
        private modalController: ModalController) {


    }
    ionViewWillLeave() {
        this.systemService.clearTimer()
    }
    ready() {

        this.animation = document.getElementsByClassName("catch");

        for (var i = 0; i < this.animation.length; i++) {
            this.animation[i].classList.add('animated');
            this.animation[i].classList.add('fadeInRight');
        }
    }
    ionViewDidEnter() {
        this.showActivityMenu = false;
        console.log(this.systemService.returnTimer())
        this.routelink.checkNecessaryHotelData().then(res => {
            this.HotelId = parseInt(this.activatedRoute.snapshot.paramMap.get("HotelId"));
            this.ActivityType = parseInt(this.activatedRoute.snapshot.paramMap.get("ActivityType"))
            this.KioskId = this.activatedRoute.snapshot.paramMap.get("KioskId")
            this.companyRecID = parseInt(this.activatedRoute.snapshot.paramMap.get("CompanyRecId"))
            this.activityID = parseInt(this.activatedRoute.snapshot.paramMap.get("ActivityId"))

            this.storageService.getItem("profile").then(res => {
                if (res == null || res == undefined) {
                    this.presentModal().then(res => {
                        this.storageService.getItem("profile").then(res => {
                            if (res) {
                                this.fillProfileInfos().then(res => {
                                    this.getActivity()
                                })
                            } else {

                            }
                        }).catch(err => {

                        })
                    })
                } else {
                    this.fillProfileInfos().then(res => {
                        this.getActivity()
                    })
                }
            }).catch(res => {
                this.presentModal().then(res => {
                    this.storageService.getItem("profile").then(res => {
                        if (res) {
                            this.fillProfileInfos().then(res => {
                                this.getActivity()
                            })
                        } else {

                        }
                    }).catch(err => {

                    })
                })
            })
        })
    }

    presentModal(): Promise<any> {
        return this.modalControllerService.presentModal(ModalTpyes.login, undefined);
    }
    private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    download_PDF_And_Open(urlLink) {
        var url = urlLink
        console.log("url: ", url)
        this.modalControllerService.presentModal(ModalTpyes.pdf, url);
    }

    getActivity() {
        debugger
        console.log('reservationPage');
        this.service.post("v1/SednaMobileB2C/GetActivityFromPms", {
            "LangID": this.LangID,
            "HotelId": this.HotelId,
            "ActivityType": this.ActivityType,
            "KioskId": this.KioskId,
            "CompanyRecId": this.companyRecID,
            "ReservationId": this.ReservationId,
            "ActivityId": this.activityID
        }).then((res: ActivityDto) => {
            this.data = res
            console.log('data: ', this.data.Title);

            console.log("single activity ", res)

            // ACTIVITY MENUS BEGIN
            this.activityCurrType = this.data.CurrType;
            this.useFoodPrice = this.data.UseFoodPrice;
            this.imgurl = this.data.ImageUrl
            // ACTIVITY MENUS END

            this.totalOfAllPrice = 0;
            this.restaurantDetail = this.domSanitazer.bypassSecurityTrustHtml(this.data.ActivityDetailDescription)
            if (this.data.PdfUrl != null || this.data.PdfUrl != undefined) {
                if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
                    this.showpdfbutton = true;
                } else {
                    this.showPDF = false;
                    // this.download_PDF_And_Open(this.data.PdfUrl)
                }


                // this.threeDHTML = this.domSanitazer.bypassSecurityTrustResourceUrl('https://docs.google.com/gview?embedded=true&url=' + this.data.PdfUrl)
                // this.showPDF = true;
            } else {

            }

            if (this.data.FutureDates.length > 0) {
                this.activityID = this.data.ActivityId //this.data.FutureDates[0].ActivityId
                this.UseChildPriceAge = this.data.FutureDates[0].UseChildPriceAge
                this.ChildPriceAge = this.data.FutureDates[0].ChildPriceAge
                this.UseDayChildReservation = this.data.FutureDates[0].UseDayChildReservation
                this.ChildReservation = this.data.FutureDates[0].ChildReservation
                this.Open_Price_Control = this.data.FutureDates[0].Open_Price_Control
                /*  for (var i = 0; i < this.data.FutureDates.length; i++) {
                     debugger;
                 
                     console.log(this.data.FutureDates[i].SelectedDate);
                     var convert = new Date(this.data.FutureDates[i].SelectedDate);
                    // convert.setMinutes(convert.getMinutes() - convert.getTimezoneOffset());
                     console.log(convert);
                     console.log(this.parseDate(this.data.FutureDates[i].SelectedDate));
                    // this.data.FutureDates[i].SelectedDate = this.dateConverter(this.data.FutureDates[i].SelectedDate)
                 } */
                this.reservationService.checkDateAvailableStatus(this.data.FutureDates);
                this.storageService.getItem("profile").then(res => {
                    debugger;
                    this.resID = res.reservationId;
                    this.checkInDate = res.checkinDate;
                    this.checkOutDate = res.checkoutDate;
                    this.companyRecID = res.CompanyRecId
                    this.titleOfProfile = res.title
                    if (this.titleOfProfile == "Chd" || this.titleOfProfile == "Inf") {
                        this.profileTitleReservationAvailability = false
                    } else {
                        this.profileTitleReservationAvailability = true
                    }
                })
            }

            this.storageService.getItem("anaMenu").then(res => {
                this.HotelId = res.MainMenus[0].HotelId
            })
            this.selectedPerson = undefined;
            this.selectedTable = undefined;
            this.selectedDate = undefined;
            this.loading.hide()
            this.ready()
        })
    }
    parseDate(input) {
        var parts = input.match(/(\d+)/g);
        // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
        return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
    }

    fillProfileInfos(): Promise<any> {
        return this.loading.show().then(res => {
            return this.storageService.getItem("profile").then(profileRES => {
                this.SumPax = profileRES.Pax
                this.Childs = profileRES.Childs
                return this.storageService.getItem("LangID").then(res => {
                    this.LangID = res
                    this.ReservationId = profileRES.reservationId
                })
            })
        })
    }
    segmentChanged(ev: any) {
        this.reservasyonSegment = ev.detail.value
    }
    ngOnInit() {
        this.min = new Date().toISOString()
        this.max = new Date().getFullYear();
    }

    reservationButtonCheck() {
        //debugger
        if ((typeof (this.selectedDate) != "undefined") && (typeof (this.selectedPerson) != "undefined" && this.selectedPerson.length > 0) && (typeof (this.selectedTable) != "undefined")) {
            this.isDateSelected = false;
        } else {
            this.isDateSelected = true;
        }
        console.log(this.selectedPerson.length)
        this.availableTables = [];

        this.tables.forEach(element => {
            this.allowSelect = true;
            if (this.selectedPerson.length < element.Pax2 && element.ControlPax === 1) {
                this.allowSelect = false;
            }
            if (this.selectedPerson.length === 0) {
                this.allowSelect = false;
            }
            if (this.selectedPerson.length > element.Pax) {
                this.allowSelect = false;
            }
            if (this.DateObjectToSend.Capacity - this.DateObjectToSend.Sold - this.selectedPerson.length < 0) {
                this.allowSelect = false;
            }
            if (this.allowSelect === true) {
                this.availableTables.push(element);
            }
            console.log(this.availableTables);
        });
    }
    onChangeDate(date) {
        //debugger;
        console.log(date.ChildReservation)
        this.DateObjectToSend = date;
        console.log(date, "date");
        var crResponse = this.reservationService.onChangeDate(date, this.selectedDate);
        this.DayCR = crResponse.DayCR;
        this.DayChildAge = crResponse.DayChildAge;
        var tableAndPersonOBJ = {
            "HotelId": this.HotelId,
            "Person": {
                "ResId": date.ResId,
                "ActivityPaid": date.Open_Price_Control,
                "DayCR": this.DayCR === null ? 0 : this.DayCR,
                "DayChildAge": this.DayChildAge === null ? 0 : this.DayChildAge,
                "ChildReservation": date.ChildReservation,
                "UseChildPriceAge": date.UseChildPriceAge,
                "ChildPriceAge": date.ChildPriceAge,
                "UseDayChildReservation": date.UseDayChildReservation
            },
            "Table": {
                "CheckoutDate": this.checkOutDate,
                "CheckinDate": this.checkInDate,
                "CompanyRecId": date.CompanyRecId,
                "ActivityId": date.ActivityId,
                "SelectedDate": this.selectedDate,
                "SumPax": this.SumPax,
                "SumChild": this.Childs,
                "Capacity": date.Capacity
            }
        }

        this.loading.show().then(res => {
            this.service.post("v1/SednaMobileB2C/GetPersonAndTableListFromPms", tableAndPersonOBJ
            ).then(resp => {
                //debugger
                if (date.ChildReservation != 0) {
                    this.persons = resp.Response.Persons

                }
                else {
                    this.persons = resp.Response.Persons
                    this.persons = this.persons.filter(x => x.Title != 'CHD');
                }
                this.reservationService.checkTableAvaible(resp.Response.Tables);
                this.tables = resp.Response.Tables

                console.log(resp);
                this.loading.hide();
            }).catch(err => {
                alert("GetPersonAndTableListFromPms error: " + JSON.stringify(err))
                this.loading.hide();
            })
        });
    }
    onChange() {
        this.reservationButtonCheck();
    }
    backClicked() {
        this.location.back();
    }
    onChangeTable(table) {
        if (this.data.PageChangeTime != null || this.data.PageChangeTime != "undefined") {
            this.PageChangeTime = this.data.PageChangeTime
        } else {
            this.PageChangeTime = 60;
        }
        if (this.downloadTimer) {
            clearInterval(this.downloadTimer);
            var a = this.systemService.timer(this.PageChangeTime)
        } else {
            var a = this.systemService.timer(this.PageChangeTime)
        }
        this.TableObjectToSend = table;
    }
    openDialog(menuSelect: number) {

        console.log(this.selectedPerson)
        console.log(this.selectedTable.CurrentPax)

        if (this.selectedPerson.length > this.selectedTable.CurrentPax) {
            return this.translate.get("toastTranslations.alacartMenuOverCapacity").subscribe(res => {
                this.toast.toast(res, "fail", 4000)
            })
        }

        // if (this.selectedTable.Pax2 > this.selectedPerson.length) {
        //     this.selectedTable = null;
        //     return this.translate.get("toastTranslations.alacartMenuOverCapacity").subscribe(res => {
        //         this.toast.toast(res, "fail", 4000)
        //     })
        // }
        this.modalData =
        {
            TableObjectToSend: this.TableObjectToSend,
            dateModel: this.DateObjectToSend,
            name: this.selectedPerson,
            date: this.selectedDate,
            table: this.selectedTable.Remark,
            capacity: this.selectedTable.capacity,
            time: this.selectedTable.time,
            detail: this.selectedTable.detail,
            menuSelect: menuSelect,
            activityMenus: this.responseActivityMenus,
            reservationNote: "B2C: " + this.reservationNote
        }

        ReservationPage.demo = this.dialog.open(AlakartModalPage, {
            data: this.modalData,
            width: '95%',
            maxWidth: 'unset',
            panelClass: 'myDialogCSS'
        });
        if (this.dialogRef) {
            this.dialogRef.afterClosed().subscribe(result => {
                console.log(`Dialog result: ${result}`);
                this.routelink.getRouteLinkParameters("/dashboard", {})
            });
        }

    }
    dateConverter(date: string) {

        if (date) {
            const splitDate: string[] = date.split('T');
            if (splitDate.length > 0) {
                //return splitTime[1].substring(0,5);
                const splitDatePart: string[] = splitDate[0].split('-');

                return splitDatePart[2] + '.' + splitDatePart[1] + '.' + splitDatePart[0]
            }
            return null;
        }
        return null;
    }
    convertToTime(date: string): string {
        if (date) {
            const splitDate: string[] = date.split('T');
            if (splitDate.length > 0) {
                //return splitTime[1].substring(0,5);
                const splitDatePart: string[] = splitDate[0].split('-');

                return splitDatePart[2] + '.' + splitDatePart[1] + '.' + splitDatePart[0]
            }
            return null;
        }
        return null;
    }
    getActivityMenusAndFoods(): void {


        let RPaxCounter = 0;
        let RChildCounter = 0;

        this.selectedPerson.forEach(element => {
            if (element.Title != undefined && element.Title != '') {
                if (element.Title === 'MR' || element.Title === 'MRS') {
                    RPaxCounter++;
                }
                else {
                    RChildCounter++;
                }
            }
        });

        const model = new GetActivityMenusAndFoodsDto(
            this.activityID,
            this.HotelId
        );
        model.RPax = RPaxCounter,
            model.RChild = RChildCounter,

            this.reservationService.getActivityMenusAndFoods(model).then((response) => {

                //console.log('menu response',response);
                if (response.ResponseType === 'Ok') {
                    //console.log('selected person',this.selectedPerson);                       
                    this.responseActivityMenus = response.Response;

                    if (this.responseActivityMenus.length > 0) {

                        this.responseActivityMenus[0].ActivityFoods.forEach(element => {
                            if (!element.ImageSource) {
                                element.ImageSource = this.ConvertData(element);
                            }
                        });

                        this.setAmountFieldByActivityFood(this.responseActivityMenus);

                        this.showActivityMenu = true

                        if (this.slides !== undefined) {
                            this.slides.lockSwipeToNext(false);
                            //this.slides.slideNext();
                            this.slides.lockSwipeToNext(true);
                            this.slides.lockSwipeToPrev(true);
                        }
                    }
                }
            }).catch((err) => {
                console.log(err);
            });



    }
    setAmountFieldByActivityFood(responseActivityMenus: ActivityMenuDto[]): void {
        responseActivityMenus.forEach((activityMenu) => {
            activityMenu.ActivityFoods.forEach((activityFood) => {
                this.getAmountOfActivityFood(activityFood, activityMenu.ResponseTotalPax, activityMenu.RPax, activityMenu.RChild);
                if (activityMenu.UseFoodPrice) {

                    //TODO:(SKN)(Bu kısımda RightFinishPrice)
                    // if (((int)aRow["Priced"] == 1) || (C_Paramaters.V_RightFinishPriceActivityActive))
                    if (activityFood.Priced == 1) {
                        activityFood.NetAmount = activityFood.Amount * activityFood.Price;
                    }
                    else {
                        activityFood.NetAmount = 0;
                    }
                }
                else {
                    activityFood.NetAmount = 0;
                }
            })
        })
    }

    getAmountOfActivityFood(activityFood: ActivityFoodDto, ResponseTotalPax: number, RPax: number, RChiild: number): any {
        //ActivityFood üzerindeki fix parametrelerine göre miktar döndürür.

        if (activityFood.FixFood === 1) {
            if (activityFood.FixPax === 1) {
                activityFood.Amount = ResponseTotalPax;
            }
            else if (activityFood.FixPax === 0) {
                activityFood.Amount = 1;
            }
        }
        if (activityFood.FixFood === 0) {
            if (activityFood.DefaultFood === 0) {
                activityFood.Amount = 0;
            }
            else if (activityFood.DefaultFood === 1) {
                activityFood.Amount = ResponseTotalPax;
            }
        }
    }

    isNextPages(nextMenuIndex: number): void {
        const activityMenu = this.responseActivityMenus[nextMenuIndex];
        activityMenu.ActivityFoods.forEach(element => {
            if (!element.ImageSource) {
                element.ImageSource = this.ConvertData(element);
            }
        });
    }

    swipeNext(): void {
        this.slides.getActiveIndex().then((idx) => {
            //this.currentMenuIndex = idx + 1;
            let isAvailable = true;

            isAvailable = this.isAvailableToNextPage(this.currentMenuIndex);

            if (isAvailable) {
                this.slides.lockSwipeToNext(false);
                this.slides.slideNext();
                this.slides.lockSwipeToNext(true);
                this.slides.lockSwipeToPrev(true);
                this.pageTop.scrollToTop();
            }

        });


    }
    isAvailableToNextPage(currentMenuIndex: number): boolean {

        let amounts = 0;

        if (currentMenuIndex + 1 <= this.responseActivityMenus.length - 1) {
            this.nextMenuIndex = this.currentMenuIndex + 1;
            this.isNextPages(this.nextMenuIndex);
        }

        const activityMenu = this.responseActivityMenus[currentMenuIndex];
        //const activityFoods = activityMenu.ActivityFoods.filter(x => x.FixFood === 0 &&  x.Priced === 0);

        if (this.data.MenuPaxControl === 1) {

            let activityMenuCount = activityMenu.ActivityFoods.length;
            let activityPriceCount = activityMenu.ActivityFoods.filter(x => x.Priced === 1).length;

            if (activityMenuCount === activityPriceCount) {
                this.currentMenuIndex++;
                return true;
            }

            const activityFoods = activityMenu.ActivityFoods.filter(x => x.FixFood === 0);
            if (activityFoods && activityFoods.length > 0) {
                const amountArray = activityFoods.map(x => x.Amount);
                amounts = amountArray.reduce((partialSum, a) => partialSum + a, 0);
                if (amounts === activityMenu.ResponseTotalPax) {
                    this.currentMenuIndex++;
                    return true;
                }
                else {
                    this.translate.get("toastTranslations.alacartMenuPasspage").subscribe(res => {
                        this.toast.toast(res, "fail", 2000)
                        return false;
                    })
                }
            }
            else {
                this.currentMenuIndex++;
                return true;
            }
        }
        else {
            this.currentMenuIndex++;
            return true
        }

    }

    menuOpenDialog(): void {

        let isAvailable = this.isAvailableToNextPage(this.currentMenuIndex);
        if (isAvailable) {
            this.openDialog(1);
        }
    }


    swipeBack(): void {
        this.slides.lockSwipeToPrev(false);
        this.slides.slidePrev();
        this.slides.lockSwipeToPrev(true);
        this.slides.lockSwipeToNext(true);
        this.slides.getActiveIndex().then((idx) => {
            this.currentMenuIndex = idx;
            this.pageTop.scrollToTop();
        });
    }

    AmountValue(activityFood: ActivityFoodDto, activityMenu: ActivityMenuDto, type: boolean): void {

        if (activityMenu && activityMenu.ActivityFoods) {
            if (type) {
                let PaxTotal = 0;
                let PaxTotalSelect = 0;
                let PaxCurrent = activityFood.Amount;

                if (this.data.ChildMenu === 0) {
                    PaxTotal = activityMenu.RPax + activityMenu.RChild;
                }
                else {
                    if (activityMenu.ChildMenu === 0) {
                        PaxTotal = activityMenu.RPax;
                    }
                    else if (activityMenu.ChildMenu === 1) {
                        PaxTotal = activityMenu.RChild;
                    }
                }

                const tempActivityFood = activityMenu.ActivityFoods.filter(x => x.MenuId == activityMenu.RecId && x.FixFood == 0)
                if (tempActivityFood && tempActivityFood.length > 0) {
                    PaxTotalSelect = tempActivityFood.map(a => a.Amount).reduce(function (a, b) {
                        return a + b;
                    })
                }
                if (activityFood.FixFood == 1) {
                    if (PaxCurrent < PaxTotal) {
                        activityFood.Amount++;
                    }
                }
                if (tempActivityFood.length > 0 && PaxTotalSelect < PaxTotal && activityFood.FixFood != 1) {
                    activityFood.Amount++;
                }
                if (activityMenu.UseFoodPrice) {
                    //TODO:(SKN)(Bu kısımda RightFinishPrice)
                    // if (((int)aRow["Priced"] == 1) || (C_Paramaters.V_RightFinishPriceActivityActive))
                    if (activityFood.Priced == 1) {
                        activityFood.NetAmount = activityFood.Amount * activityFood.Price;
                    }
                    else {
                        activityFood.NetAmount = 0;
                    }
                    if (tempActivityFood.length > 0) {
                        this.totalOfAllPrice = tempActivityFood.map(a => a.NetAmount).reduce(function (a, b) {
                            return a + b;
                        })
                    }
                }
            }
            else {
                if (activityFood.Amount != 0) {
                    activityFood.Amount--;
                }
                if (activityMenu.UseFoodPrice) {
                    //TODO:(SKN)(Bu kısımda RightFinishPrice)
                    // if (((int)aRow["Priced"] == 1) || (C_Paramaters.V_RightFinishPriceActivityActive))
                    if (activityFood.Priced == 1) {
                        activityFood.NetAmount = activityFood.Amount * activityFood.Price;
                    }
                    else {
                        activityFood.NetAmount = 0;
                    }
                    this.totalOfAllPrice = activityMenu.ActivityFoods.map(a => a.NetAmount).reduce(function (a, b) {
                        return a + b;
                    })
                }
            }
        }
    }

    FindFeatures(activityFood: ActivityFoodDto): string {
        let temp: string = "";
        if (activityFood.Priced == 1) {
            temp += "P";
        }
        if (activityFood.FixFood == 1) {
            temp += "F";
        }
        return temp;
    }

    //Bu kısım sonradan revize edilmesi lazım hızlı olması için böyle yapılmak durumunda kalındı(SKN)

    FindLanguageMenu(activityMenu: ActivityMenuDto): string {

        if (this.LangID === 1) {
            //Turkce
            return activityMenu.MenuTUR.toString();
        }
        else if (this.LangID === 184) {
            //İngilizce
            return activityMenu.MenuENG.toString();
        }
        else if (this.LangID === 185) {
            //Rusca
            return activityMenu.MenuRUS.toString();
        }
        else if (this.LangID === 186) {
            //Almanca
            return activityMenu.MenuDEU.toString();
        }
        else {
            //Default
            return activityMenu.Menu.toString();
        }

    }

    FindLanguageActivityFood(activityFood: ActivityFoodDto): string {

        if (this.LangID === 1) {
            //Turkce
            return activityFood.FoodTUR.toString();
        }
        else if (this.LangID === 184) {
            //İngilizce
            return activityFood.FoodENG.toString();
        }
        else if (this.LangID === 185) {
            //Rusca
            return activityFood.FoodRUS.toString();
        }
        else if (this.LangID === 186) {
            //Almanca
            return activityFood.FoodDEU.toString();
        }
        else {
            //Default
            return activityFood.Food.toString();
        }

    }

    ConvertData(activityFood: ActivityFoodDto): any {
        if (activityFood.ImageFood) {
            let objectURL = 'data:image/png;base64,' + activityFood.ImageFood;
            var imgSource = this.domSanitazer.bypassSecurityTrustUrl(objectURL);
            return imgSource;
        }
        return null;
    }

    openPdfModal(url) {

        this.modalControllerService.presentModal(ModalTpyes.pdf, url);

        // const modal = await this.modalController.create({
        //     component: PdfViewerModalComponent,
        //     componentProps: {
        //         pdfSrc: this.data.PdfUrl
        //     }
        // });

        // await modal.present();
    }

}

