import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EnumLanguageType } from 'src/app/models/enums/enum-language-type.enum';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.page.html',
  styleUrls: ['./weather.page.scss'],
})
export class WeatherPage {

  nem;
  ruzgar;
  gundogumu;
  gunbatimi;
  weatherData;
  sicaklik: Array<any> = [];
  icons: Array<any> = [];
  sicaklikToday;
  days: Array<string> = ["Paz", "P.tesi", "Sal", "Çarş", "Perş", "Cum", "C.tesi"]

  aryDates: Array<string> = [];
  locationName: any;

  constructor(
    private storageService: StorageService,
    private loading: LoadingService,
    public modalController: ModalController) { }
  ionViewDidEnter() {
    this.storageService.getItem("LangID").then(res => {
      //TODO ALWAYS: her dil dosyası eklendiğinde buraya arrayini ekleyeceksin
      if (res == EnumLanguageType.Turkish) {
        this.storageService.setItem("daysOfWeek", this.days)
        this.getweatherData();
        this.GetDates(new Date())
      }
      else if (res == EnumLanguageType.English) {
        this.days = ["Sun", "Mon", "Tue", "Wedn", "Thur", "Fri", "Sat"]
        this.storageService.setItem("daysOfWeek", this.days)
        this.getweatherData();
        this.GetDates(new Date())
      }
      else if (res == EnumLanguageType.Deutsch){
        this.days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
        this.storageService.setItem("daysOfWeek", this.days)
        this.getweatherData();
        this.GetDates(new Date())
      }
      else if (res == EnumLanguageType.русский){
        this.days = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
        this.storageService.setItem("daysOfWeek", this.days)
        this.getweatherData();
        this.GetDates(new Date())
      }
      else {
        this.storageService.getItem("daysOfWeek").then(res => {
          this.days = res
          this.getweatherData();
          this.GetDates(new Date())
        }).catch(err => {
          // alert('test: ' + JSON.stringify(err))
        })
      }
    }).catch(err => {
      // alert('LangID alert weather: ' + JSON.stringify(err))
    })

  }
  ngOnInit() {
  }


  GetDates(startDate) {
    for (var i = 0; i < 7; i++) {
      var currentDate = new Date();
      currentDate.setDate(startDate.getDate() + i);
      this.aryDates.push(this.DayAsString(currentDate.getDay()));
    }
    console.log(this.aryDates)
    return this.aryDates;
  }
  DayAsString(dayIndex) {
    return this.days[dayIndex];
  }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  // apiden gelen iconları kendi iconlarımıza dönüştürüyoruz
  iconConverter(icon) {
    switch (icon) {
      case "clear sky":
        return "sunny-outline"
        break;
      case "light rain":
        return "rainy-outline"
        break;
      case "few clouds":
        return "partly-sunny-outline"
        break;
      case "scattered clouds":
        return "cloudy-outline"
        break;
      case "broken clouds":
        return "cloudy-outline"
        break;
      case "shower rain":
        return "thunderstorm-outline"
        break;
      case "rain":
        return "rainy-outline"
        break;
      case "thunderstorm":
        return "thunderstorm-outline"
        break;
      case "snow":
        return "snow-outline"
        break;
      case "mist":
        return "options-outline"
        break;

      default:
        break;
    }
  }
  convertTime(time) {
    var date = new Date(time * 1000);
    var timestr = date.toLocaleTimeString();
    return timestr;
  }
  getweatherData() {
    this.loading.show().then(res => {
      this.storageService.getItem("anaMenu").then(res => {
        if (res.MobileHotelDefinition.Weather.Data) {
          var data = JSON.parse(res.MobileHotelDefinition.Weather.Data)

          this.weatherData = data
          this.locationName = data.OpenWeather.city.name


          this.sicaklikToday = data.OpenWeather.list[0].main.feels_like //bugünün sıcaklık değeri
          this.nem = data.OpenWeather.list[0].main.humidity  //nem
          this.ruzgar = data.OpenWeather.list[0].wind.speed  //rüzgar
          this.gundogumu = data.OpenWeather.city.sunrise; //gün doğumu
          this.gunbatimi = data.OpenWeather.city.sunset;//gün batımı

          this.gundogumu = this.convertTime(this.gundogumu)
          this.gunbatimi = this.convertTime(this.gunbatimi)

          for (var i = 0; i < data.OpenWeather.list.length - 1; i++) {
            this.sicaklik.push(data.OpenWeather.list[i].main.feels_like)  //7 günlük sıcaklık
            this.icons.push(this.iconConverter(data.OpenWeather.list[i].weather[0].description))  //7 günlük icon
          }
          this.loading.hide()
        } else {
          console.log("hava durumu data yok")
          this.loading.hide()
        }

      })
    })
  }
}
