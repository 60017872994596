import { Component, OnInit, ViewChild } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { HttpService } from 'src/app/services/http.service';
import { ProfileRequest } from 'src/app/models/ProfileRequest';
import { LoadingService } from 'src/app/services/loading.service';
import { Location } from '@angular/common';
import { ToasterService } from 'src/app/services/toaster.service';
import { RouteLinkParametersService } from 'src/app/services/route-link-parameters.service';
import { SharedService } from 'src/app/services/shared.service';
import { SystemService } from 'src/app/services/system.service';
import { TranslateService } from '@ngx-translate/core';
import { IonInput, ModalController, Platform } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalControllerService } from 'src/app/services/modalController.service';
import { ModalTpyes } from 'src/app/models/enums/modal-types.enum';
import { Device } from '@ionic-native/device/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { oneSignalPostModel } from 'src/app/models/oneSignalDatabaseModel';
import { TranslatingService } from 'src/app/services/translate.service';
import { isNullOrUndefined } from 'util';
import { environment } from 'src/environments/environment';
import doorPlugin from 'src/app/interfaces/IDoorLockPlugin';
import { AssaaployService } from 'src/app/services/assaaploy.service';
import { DoorLockLoginPage } from '../door-lock-login/door-lock-login.page';
import OneSignal from 'onesignal-cordova-plugin';
const MAX_VALID_YR = 9999;
const MIN_VALID_YR = 1800;
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  @ViewChild('dayInput') dayInput: IonInput;
  @ViewChild('monthInput') monthInput: IonInput;
  @ViewChild('yearInput') yearInput: IonInput;
  a = true //loginliyken kvkk checkboxları dolu gelsin diye
  formGroup: FormGroup;
  VoucherValidate = new FormControl('', [Validators.required]);
  loginTypes: Array<any>;
  profile: any;
  FirstName;
  LastName;
  Voucher;
  Room;
  Operator;
  TcNo;
  PassportNo;
  ReservationId;
  AssaKey?: string;
  CardId?: string;
  GuestId;
  DateOfBirth;
  firstNameCheck = 0;
  lastNameCheck = 0;
  voucherCheck = 0;
  roomCheck = 0;
  operatorCheck = 0;
  tcNoCheck = 0;
  pasaportCheck = 0;
  reservationIDCheck = 0;
  guestIDCheck = 0;
  birthdateCheck = 0;
  emailCheck = 0;
  passportCheckBoxCheck = 0;
  tcCheckBoxCheck = 0;
  PassOrTcCheck = 0;
  // toggleCheck = 0;
  // tcNoOrPassCheck = 0;
  day;
  month;
  year;
  dateStr: string;
  oneSignalObjectForDatabase: oneSignalPostModel = {
    GuestId: 0, HotelId: 0, firstname: "", lastname: "", deviceModel: "", devicePlatform: "",
    deviceCordova: "", deviceUUID: "", deviceVersion: "", deviceToken: "", notification: true, languagecode: ""
  }

  customYearValues = [2020, 2016, 2008, 2004, 2000, 1996, 1990];
  customDayShortNames = ['s\u00f8n', 'man', 'tir', 'ons', 'tor', 'fre', 'l\u00f8r'];
  customPickerOptions: any;
  backButtonVisibility: boolean = false;
  hotelID: any;
  loginObject: ProfileRequest = {
    FirstName: null,
    LastName: null,
    Voucher: null,
    Room: null,
    Operator: null,
    PassportNo: null,
    ReservationId: null,
    GuestId: null,
    DateOfBirthStr: null,
    HotelId: null,
    Email: null,
    TcNo: null,
    Phone: null,
    NotificationTypes: null,
    KvkkType: null,
    // TcOrPassport: null
  };
  kvkk: boolean = true;
  terms: boolean = true;
  notificationTypes: Array<any> = []
  notificationTypeValue
  qrVALUE: any
  isEmailSelectedAndNull: boolean;
  isPhoneSelectedAndNull: boolean;
  oneSignalApiKey: any;
  LangID: any;
  LoginSegment: any = 1;
  notificationTypesAfterLoggedInVariable: any;
  companyRecIdAssa: any;
  companyId: any;
  accessToken;
  guestId: number;
  deviceId: string;
  confirmed: boolean;
  storageConfirm: boolean
  isDoorLockParamSelected: boolean = false;
  // selectedOptionPassport: boolean = false;
  // selectedOptionPassport: boolean = false;
  // isRequiredPassport: boolean = false;
  // isRequiredTc: boolean = false;
  // validatorsPassport: Validators
  // validatorsTc: Validators

  tcChecked: boolean = true;
  passportChecked: boolean = false;
  tcOrPassDefCheck: boolean = false;

  constructor(private storageService: StorageService,
    private tanslatingService: TranslatingService,
    private device: Device,
    public formBuilder: FormBuilder,
    private httpService: HttpService,
    public modalController: ModalController,
    private systemService: SystemService,
    private modalControllerService: ModalControllerService,
    private loading: LoadingService,
    private toast: ToasterService,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private shared: SharedService,
    private routeLink: RouteLinkParametersService,
    private assaaployService: AssaaployService,
    private route: ActivatedRoute,
    private platform: Platform) {
    this.formGroup = this.formBuilder.group({});

    this.customPickerOptions = {
      buttons: [{
        text: 'Save',
        handler: () => console.log('Clicked Save!')
      }, {
        text: 'Log',
        handler: () => {
          console.log('Clicked Log. Do not Dismiss.');
          return false;
        }
      }]
    }

    this.storageService.getItem("anaMenu").then(resAnaMenu => {
      if (resAnaMenu) {
        this.isDoorLockParamSelected = resAnaMenu.MobileHotelDefinition.MobileApplicationDefinition.DoorLock;
      }
    });
    this.storageService.getItem("LangID").then(res => {
      this.LangID = res
      //alert("language : " + res);

    });
    this.storageService.getItem("confirmed").then(resConfirmed => {
      this.storageConfirm = resConfirmed;
    });

  }
  async goToDoNativeApp(): Promise<void> {
    this.storageService.getItem("confirmed").then(async resConfirmed => {
      this.storageConfirm = resConfirmed;
      if (this.storageConfirm != undefined && this.storageConfirm === true) {
        this.storageService.getItem("anaMenu").then(resAnaMenu => {
          if (resAnaMenu != null || resAnaMenu != undefined) {
            this.companyRecIdAssa = resAnaMenu.MobileHotelDefinition.CompanyId;
            this.companyId = resAnaMenu.MobileHotelDefinition.CompanyKvkkCondition.CompanyId;
            this.storageService.getItem("profile").then(async resProfile => {
              this.ReservationId = resProfile.reservationId;
              this.guestId = resProfile.guestId;
              this.deviceId = this.device.uuid;
              const localEndpointId = localStorage.getItem('endpointId') ?? '';
              const localApployKey = localStorage.getItem('AssaaployKey');
              const response = await this.assaaployService.getAssaaployKey2(this.companyRecIdAssa, this.ReservationId, this.companyId, this.guestId, this.profile.roomNumber, this.deviceId, localApployKey, localEndpointId).toPromise();
              if (response != null && response != undefined && response.ResponseType === 'Ok') {

                this.storageService.setItem('endpointId', response.Response.EndpointId)
                this.storageService.setItem('AssaaployKey', response.Response.ApployKey)
                this.AssaKey = response.Response.ApployKey;
                this.CardId = response.Response.CardId;
                //debugger;
                // alert('api adres' + environment.apiAddress + "/api/")
                // alert('if öncesi key ' + this.AssaKey);
                // alert('imesaj ' + response.message);
                // alert(JSON.stringify(response));
                // alert(this.CardId);
                if (response.message !== undefined && response.message?.indexOf('already exists') !== -1) {
                  if (localStorage.getItem('AssaaployKey') !== '' && localStorage.getItem('AssaaployKey') !== undefined && localStorage.getItem('AssaaployKey') !== null) {
                    this.AssaKey = localStorage.getItem('AssaaployKey').toString().trim() ?? '';
                    //this.AssaKey = this.AssaKey.substring(1, this.AssaKey.length - 1);
                  }
                }
                //   alert('if sonrası key ' + this.profile.roomNumber);

                await doorPlugin.doorLock({
                  doorLockKey: this.AssaKey,
                  url: environment.apiAddress + "/api/v1/SednaMobileB2C/GenerateAssaKeyHotel",
                  Token: "",
                  ResId: this.ReservationId,
                  CompanyRecId: this.companyRecIdAssa,
                  EndpointId: this.ReservationId,
                  CompanyId: this.companyId,
                  GuestId: this.guestId,
                  DeviceId: this.deviceId,
                  CardId: this.CardId,
                  RoomNumber: this.profile.roomNumber
                }

                );
              } else {
                console.log(response);
                alert("NASI YANİ /r" + response.Message);
              }

            })
          }
        });
      }
      else {
        const modal = await this.modalControllerService.ShowModal(DoorLockLoginPage, undefined);
        const modalResponse = modal.onDidDismiss().then(res => {
          console.log("başarılı mı? ", res.data.datas);
          this.confirmed = res.data.datas;
          //alert(this.confirmed);
          if (this.confirmed != undefined && this.confirmed === true) {
            this.storageService.setItem("confirmed", this.confirmed);
            this.storageService.getItem("anaMenu").then(resAnaMenu => {
              if (resAnaMenu != null || resAnaMenu != undefined) {
                this.companyRecIdAssa = resAnaMenu.MobileHotelDefinition.CompanyId;
                this.companyId = resAnaMenu.MobileHotelDefinition.CompanyKvkkCondition.CompanyId;
                this.storageService.getItem("profile").then(async resProfile => {
                  this.ReservationId = resProfile.reservationId;
                  this.guestId = resProfile.guestId;
                  this.deviceId = this.device.uuid;
                  const localEndpointId = localStorage.getItem('endpointId') ?? '';
                  const localApployKey = localStorage.getItem('AssaaployKey');
                  const response = await this.assaaployService.getAssaaployKey2(this.companyRecIdAssa, this.ReservationId, this.companyId, this.guestId, this.profile.roomNumber, this.deviceId, localApployKey, localEndpointId).toPromise();
                  if (response != null && response != undefined && response.ResponseType === 'Ok') {

                    this.storageService.setItem('endpointId', response.Response.EndpointId)
                    this.storageService.setItem('AssaaployKey', response.Response.ApployKey)
                    this.AssaKey = response.Response.ApployKey;
                    this.CardId = response.Response.CardId;
                    //debugger;
                    // alert('api adres' + environment.apiAddress + "/api/")
                    // alert('if öncesi key ' + this.AssaKey);
                    // alert('imesaj ' + response.message);
                    // alert(JSON.stringify(response));
                    // alert(this.CardId);
                    if (response.message !== undefined && response.message?.indexOf('already exists') !== -1) {
                      if (localStorage.getItem('AssaaployKey') !== '' && localStorage.getItem('AssaaployKey') !== undefined && localStorage.getItem('AssaaployKey') !== null) {
                        this.AssaKey = localStorage.getItem('AssaaployKey').toString().trim() ?? '';
                        //this.AssaKey = this.AssaKey.substring(1, this.AssaKey.length - 1);
                      }
                    }
                    //   alert('if sonrası key ' + this.profile.roomNumber);

                    await doorPlugin.doorLock({
                      doorLockKey: this.AssaKey,
                      url: environment.apiAddress + "/api/v1/SednaMobileB2C/GenerateAssaKeyHotel",
                      Token: "",
                      ResId: this.ReservationId,
                      CompanyRecId: this.companyRecIdAssa,
                      EndpointId: this.ReservationId,
                      CompanyId: this.companyId,
                      GuestId: this.guestId,
                      DeviceId: this.deviceId,
                      CardId: this.CardId,
                      RoomNumber: this.profile.roomNumber
                    }

                    );
                  } else {
                    console.log(response);
                    alert("ÖYLE Mİ ? : " + response.Message);
                  }
                })
              }
            });
          }
          else if (this.confirmed === false) {

          }
        });
        await modal.present();
      }
    });


  }
  presentKVKKAndTerms(key: ModalTpyes) {
    switch (key) {
      case 9:
        this.modalControllerService.presentModal(ModalTpyes.kvkk, undefined);
        break;

      case 10:
        this.modalControllerService.presentModal(ModalTpyes.termsAndConditions, undefined);
        break;

      default:
        break;
    }

  }

  createFormControl(key) {
    switch (key) {
      case "FirstName":
        this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
        break;

      case "LastName":
        this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
        break;

      case "Voucher":
        this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
        break;

      case "Room":
        this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
        break;

      case "Operator":
        this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
        break;

      // case "PassportCheckBox":
      //   this.formGroup.addControl(key + "Control", new FormControl(""));
      //   break;

      // case "TcCheckBox":
      //   this.formGroup.addControl(key + "Control", new FormControl(""));
      //   break;

      case "PassOrTc":
        this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
        break;

      // case "Toggle":
      //   this.formGroup.addControl(key + "Control", new FormControl(""));
      //   break;

      // case "TcNo":
      //   this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
      //   break;

      // case "PassportNo":
      //   this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
      //   break;

      case "ReservationId":
        this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
        break;
      case "Email":
        this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
        break;

      case "GuestId":
        this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
        break;

      case "DateOfBirth":
        this.formGroup.addControl(key + "Day" + "Control", new FormControl("", Validators.required));
        this.formGroup.addControl(key + "Month" + "Control", new FormControl("", Validators.required));
        this.formGroup.addControl(key + "Year" + "Control", new FormControl("", Validators.required));
        break;

      // case 'TcOrPassport':
      //   this.formGroup.addControl(key + "Control", new FormControl("", Validators.required));
      //   break;

      default:
        break;
    }
  }
  getErrorMessage() {
    if (this.VoucherValidate.hasError('required')) {
      return 'You must enter a value';
    }

    return this.VoucherValidate.hasError('email') ? 'Not a valid email' : '';
  }
  backClicked() { this.routeLink.getRouteLinkParameters("/dashboard", {}) }

  ngOnInit() {
    this.routeLink.checkNecessaryHotelData().then(res => {

      this.storageService.getItem("hotelId").then(res => {
        this.hotelID = res;
      });

      this.storageService.getItem("profile").then(res => {
        this.profile = res

        if (this.profile.Email2 == null || this.profile.Email2 == undefined || this.profile.Email2 == "") {
          if (this.profile.email != null || this.profile.email != undefined || this.profile.email != "") {
            this.profile.Email2 = this.profile.email
          }
        }
        if (this.profile.KvkkType != null || this.profile.KvkkType != undefined) {
          this.kvkk = this.profile.KvkkType == 3 ? true : false;
        }
        this.profile.checkinDate = this.systemService.dateConverterFromDaysToYear(this.profile.checkinDate)
        this.profile.checkoutDate = this.systemService.dateConverterFromDaysToYear(this.profile.checkoutDate)

      }).catch(res => {
        // alert("err profile: " + res)
      });

      this.storageService.getItem("loginTypes").then(response => {
        if (response !== null && response !== undefined) {
          if (response.find(x => x == "TcNo" || x == "PassportNo")) {
            response = response.concat(['PassOrTc']);
            this.tcOrPassDefCheck = true;
            // this.isRequiredTc = true;
            // this.validatorsTc = this.isRequiredTc ? [Validators.required] : [];
            // this.validatorsPassport = this.isRequiredPassport ? [Validators.required] : [];
          }
          this.loginTypes = response;
          console.table(this.loginTypes);
          for (var i = 0; i < this.loginTypes.length; i++) {
            this.loginParametersShow(this.loginTypes[i]);
          }
          if (this.notificationTypes.length == 0) {
            this.initializeNotificationTypes()
          }
        }
      });
    });
  }
  onYearInputChange() {
    if (this.year.toString().length === 5) {
      this.yearInput.value = this.year.toString().substring(0, 4); // Son karakteri sil
    }
  }

  switchFocus(event, nextInput) {
    if (event.target.value.length === event.target.max.length) {
      nextInput.setFocus();
    }
  }

  initializeNotificationTypes() {
    this.storageService.getItem("anaMenu").then(res => {
      if (res != null || res != undefined) {
        var str: any = res.MobileHotelDefinition.NotificationTypes
        this.companyRecIdAssa = res.MobileHotelDefinition.CompanyId
        this.oneSignalApiKey = res.MobileHotelDefinition.OneSignalAppId

        str = str ? str.toString() : str;

        if (str && str.length > 1) {
          str = str.split(",");
          for (var i = 0; i < str.length; i++) {
            str[i] = parseInt(str[i])
          }
        }


        if (str && str.length > 1) {
          for (var i = 0; i < str.length; i++) {
            if (str[i] < str[i + 1]) {
              var temp
              temp = str[i]
              str[i] = str[i + 1]
              str[i + 1] = temp
            }
          }
        }
        if (str) {
          for (var i = 0; i < str.length; i++) {
            if (str.length == 1) {
              str = str.toString()
              this.notificationTypes.push({ value: str[0], completed: true })
            } else {
              str[i] = str[i].toString()
              this.setNotificationTypes(str[i])
            }
          }
        }


        this.storageService.getItem("profile").then(profile => {
          if (profile != undefined && profile != null && profile != "") {
            this.notificationTypesAfterLoggedIn(profile.NotificationType)

          }
        }).catch(err => { })
      }
    })
  }

  checkForMukerrer(value) {
    let elementsIndex = this.notificationTypes.findIndex(element => element.value == value)
    return elementsIndex
  }
  setNotificationTypes(type) {
    switch (type) {
      case "1":
        if (this.checkForMukerrer(type) == -1) {
          this.notificationTypes.push({ value: type, name: "Whatsapp", completed: false })
        } else {

        }
        break;

      case "2":
        if (this.checkForMukerrer(type) == -1) {
          this.notificationTypes.push({ value: type, name: "Email", completed: false })
        } else {

        }

        break;

      case "0":
        if (this.checkForMukerrer(type) == -1) {
          this.notificationTypes.push({ value: type, name: "Mobile Notification", completed: false })
        } else {

        }

        break;

      default:
        break;
    }
  }

  radioChange(data) {
    console.log(data)
  }

  selectedValueForUpdateNotificationTypes(value) {
    let elementsIndex = this.notificationTypes.findIndex(element => element.value == value)
    this.notificationTypes[elementsIndex] = { ...this.notificationTypes[elementsIndex], completed: true }
    // for(var i = 0; i<this.notificationTypes.length; i++){
    //   if(this.notificationTypes[i].value == value){
    //     this.notificationTypes[i] = {...this.notificationTypes[i], completed: true}
    //   }
    // }
    console.log(this.notificationTypes)
  }

  notificationTypesAfterLoggedIn(notificationTypes) {
    var stringArrayOfNotificationTypesAfterLoggedIn = notificationTypes.toString()
    console.log(stringArrayOfNotificationTypesAfterLoggedIn)
    for (var i = 0; i < stringArrayOfNotificationTypesAfterLoggedIn.length; i++) {
      this.selectedValueForUpdateNotificationTypes(stringArrayOfNotificationTypesAfterLoggedIn[i])
    }
  }

  isLeap(year) {

    // Return true if year
    // is a multiple of 4 and
    // not multiple of 100.
    // OR year is multiple of 400.
    return (((year % 4 == 0) &&
      (year % 100 != 0)) ||
      (year % 400 == 0));
  }


  isValidDate(d, m, y) {

    // If year, month and day
    // are not in given range
    if (y > MAX_VALID_YR ||
      y < MIN_VALID_YR)
      return false;

    if (m < 1 || m > 12)
      return false;
    if (d < 1 || d > 31)
      return false;

    // Handle February month
    // with leap year
    if (m == 2) {
      if (this.isLeap(y))
        return (d <= 29);
      else
        return (d <= 28);
    }

    // Months of April, June,
    // Sept and Nov must have
    // number of days less than
    // or equal to 30.
    if (m == 4 || m == 6 ||
      m == 9 || m == 11)
      return (d <= 30);

    return true;
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getOrCreateBrowserId() {
    let browserId = localStorage.getItem('browserId');

    if (!browserId) {
      browserId = this.generateUUID();
      localStorage.setItem('browserId', browserId);
    }

    return browserId;
  }

  login(value) {
    this.deviceId = this.device.uuid;

    if (this.deviceId === null) {
      const browserId = this.getOrCreateBrowserId();
    }

    this.loginObject.HotelId = this.hotelID
    var lastNotificationTypes = "";
    for (var i = 0; i < this.notificationTypes.length; i++) {
      if (this.notificationTypes[i].completed == true) {
        lastNotificationTypes = lastNotificationTypes.concat(this.notificationTypes[i].value);
      }
      if (this.notificationTypes[i].name == "Email") {
        if (this.notificationTypes[i].completed == true && (this.loginObject.Email == null || this.loginObject.Email == undefined || this.loginObject.Email == "")) {
          this.isEmailSelectedAndNull = true
        } else {
          this.isEmailSelectedAndNull = false
        }
      }
      if (this.notificationTypes[i].name == "Whatsapp") {
        if (this.notificationTypes[i].completed == true && (this.loginObject.Phone == null || this.loginObject.Phone == undefined || this.loginObject.Phone == "")) {
          this.isPhoneSelectedAndNull = true
        } else {
          this.isPhoneSelectedAndNull = false
        }
      }
    }
    if (lastNotificationTypes == "") {
      this.translate.get("toastTranslations.selectAtLeastOneNotificationTypes").subscribe(res => {
        this.toast.toast(res, "warning", 2000)
      })
    } else if (this.isPhoneSelectedAndNull) {
      this.translate.get("toastTranslations.fillPhoneNumber").subscribe(res => {
        this.toast.toast(res, "warning", 2000)
      })
    } else if (this.isEmailSelectedAndNull) {
      this.translate.get("toastTranslations.fillEmail").subscribe(res => {
        this.toast.toast(res, "warning", 2000)
      })
    }
    else {
      console.log("son notificationtypes", parseInt(lastNotificationTypes))
      this.loginObject["NotificationType"] = parseInt(lastNotificationTypes)
      if (this.day && this.month && this.year) {
        if (this.isValidDate(this.day, this.month, this.year)) {
          this.dateStr = this.year.toString() + '-' + this.month.toString() + '-' + this.day.toString();
          this.loginObject.DateOfBirthStr = this.dateStr;
        }
        else {
          return this.translate.get("toastTranslations.controlBirthDate").subscribe(res => {
            this.toast.toast(res, "fail", 3000)
          })
        }
      }


      // if (this.loginObject.DateOfBirthStr) {
      //   this.loginObject.DateOfBirthStr = this.systemService.dateConverter(this.loginObject.DateOfBirthStr)
      // }
      this.loginObject.KvkkType = this.kvkk;
      if (this.passportChecked) {
        this.loginObject.PassportNo = this.loginObject.TcNo;
        this.loginObject.TcNo = "";
      }
      console.log("login Object: ", this.loginObject)
      this.loading.show().then(res => {
        this.httpService.post('v1/SednaMobileB2C/GetProfileFromPms', this.loginObject).then(res => {
          this.loading.hide()
          console.log(res)

          if (res.ResponseType == "Ok") {

            this.profile = res.Response
            if (this.profile.Email2 == null || this.profile.Email2 == undefined || this.profile.Email2 == "") {
              if (this.profile.email != null || this.profile.email != undefined || this.profile.email != "") {
                this.profile.Email2 = this.profile.email
              }
            }
            this.storageService.setItem("profile", this.profile)
            this.storageService.getItem("profile").then(res => {
              console.log(res)
              this.storageService.setItem("reservationId", res.reservationId)
              this.storageService.setItem("guestId", res.guestId)
              this.GuestId = res.guestId
              this.storageService.setItem("isLogin", true)
              this.shared.updateIsLogin(true)
              this.notificationTypesAfterLoggedIn(res.NotificationType)
              this.profile.checkinDate = this.systemService.dateConverterFromDaysToYear(this.profile.checkinDate)
              this.profile.checkoutDate = this.systemService.dateConverterFromDaysToYear(this.profile.checkoutDate)

              this.oneSignalObjectForDatabase.GuestId = res.guestId
              this.oneSignalObjectForDatabase.firstname = res.firstname
              this.oneSignalObjectForDatabase.lastname = res.lastname
              this.oneSignalObjectForDatabase.HotelId = this.hotelID
              this.oneSignalObjectForDatabase.languagecode = this.tanslatingService.languageSelector(this.LangID)
              //alert(this.oneSignalObjectForDatabase.languagecode);
              this.oneSignalObjectForDatabase.deviceCordova = this.device.cordova
              this.oneSignalObjectForDatabase.devicePlatform = this.device.platform
              this.oneSignalObjectForDatabase.deviceModel = this.device.model
              this.oneSignalObjectForDatabase.deviceUUID = this.device.uuid
              this.oneSignalObjectForDatabase.deviceVersion = this.device.version
              //alert(this.oneSignalApiKey);

              this.setupPushAndUpdateMobileDeviceKey(this.oneSignalApiKey, this.oneSignalObjectForDatabase);




              //One signal dökümantasyonunda bulunan push notification hizmeti!
              //this.oneSignalPush()

              //END



            }).catch(err => {
              console.log("profile api istek sonrası hata", err)
              // alert(JSON.stringify("profile cacheten çekerken hata" + err))
            })

            this.translate.get("toastTranslations.loginOK").subscribe(res => {
              this.toast.toast(this.profile.firstname + " " + this.profile.lastname + res, "success", 2000)
              this.dismiss()
            })
          } else {
            this.translate.get("toastTranslations.loginFail").subscribe(res => {
              this.toast.toast(res, "fail", 2000)
            })
          }
        }).catch(err => {
          console.log("login catch ", err)
        });
      });
    }
  }
  oneSignalPush() {
    window["plugins"].OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });

    var notificationOpenedCallback = function (jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    };

    // Set your iOS Settings
    var iosSettings = {};
    iosSettings["kOSSettingsKeyAutoPrompt"] = false;
    iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;


    window["plugins"].OneSignal
      .startInit(this.oneSignalApiKey)
      .handleNotificationOpened(notificationOpenedCallback)
      .iOSSettings(iosSettings)
      .inFocusDisplaying(window["plugins"].OneSignal.OSInFocusDisplayOption.Notification)
      .endInit();
    var self = this
    window["plugins"].OneSignal.getPermissionSubscriptionState(function (status) {
      status.subscriptionStatus.userId; // String: OneSignal Player ID
      status.subscriptionStatus.pushToken;
      self.oneSignalObjectForDatabase.deviceToken = status.subscriptionStatus.userId
      self.httpService.post("v1/SednaMobileB2C/AddOrUpdateMobileDeviceKey", self.oneSignalObjectForDatabase).then(res => {
      })
    })

    window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });

  }
  setupPushAndUpdateMobileDeviceKey(appId, oneSignalPostObject) {

    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      let msg = jsonData.notification.body;
      this.toast.toast(msg, "success", 1000);
    });

    //Method for handling notifications received while app in foreground
    // OneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent: NotificationReceivedEvent) => {
    //   alert("OneSignal: notification will show in foreground:"+ JSON.stringify(notificationReceivedEvent));
    //   let notification = notificationReceivedEvent.getNotification();
    //   alert("notification: " + JSON.stringify(notification));
    //   notificationReceivedEvent.complete(notification)
    // })

    // OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {

    // });

    OneSignal.getDeviceState((state) => {
      oneSignalPostObject.deviceToken = state.userId;
      oneSignalPostObject.AppId = appId;
      this.httpService.post("v1/SednaMobileB2C/AddOrUpdateMobileDeviceKey", oneSignalPostObject).then(res => {
      }).catch(ex => {

      });
    });

  }
  updateProfile() {
    var lastNotificationTypes = ""
    for (var i = 0; i < this.notificationTypes.length; i++) {
      if (this.notificationTypes[i].completed == true) {
        lastNotificationTypes = lastNotificationTypes.concat(this.notificationTypes[i].value);
      }
      if (this.notificationTypes[i].name == "Email") {
        if (this.notificationTypes[i].completed == true && (this.profile.Email2 == null || this.profile.Email2 == undefined || this.profile.Email2 == "")) {
          this.isEmailSelectedAndNull = true
        } else {
          this.isEmailSelectedAndNull = false
        }
      }
      if (this.notificationTypes[i].name == "Whatsapp") {
        if (this.notificationTypes[i].completed == true && (this.profile.Phone2 == null || this.profile.Phone2 == undefined || this.profile.Phone2 == "")) {
          this.isPhoneSelectedAndNull = true
        } else {
          this.isPhoneSelectedAndNull = false
        }
      }
    }
    if (lastNotificationTypes == "") {
      this.translate.get("toastTranslations.selectAtLeastOneNotificationTypes").subscribe(res => {
        this.toast.toast(res, "warning", 2000)
      })
    } else if (this.isPhoneSelectedAndNull) {
      this.translate.get("toastTranslations.fillPhoneNumber").subscribe(res => {
        this.toast.toast(res, "warning", 2000)
      })
    } else if (this.isEmailSelectedAndNull) {
      this.translate.get("toastTranslations.fillEmail").subscribe(res => {
        this.toast.toast(res, "warning", 2000)
      })
    }
    else {
      var updateProfileObject = {
        GuestId: this.profile.guestId,
        Mobile: this.profile.Phone2,
        Email: this.profile.Email2,
        NotificationTypes: parseInt(lastNotificationTypes),
        HotelId: this.hotelID,
        CompanyRecId: this.companyRecIdAssa,
        ProfileRequest: {
          FirstName: null,
          LastName: null,
          Voucher: null,
          Room: null,
          Operator: null,
          PassportNo: null,
          ReservationId: null,
          GuestId: this.profile.guestId,
          DateOfBirthStr: null,
          HotelId: this.hotelID,
          Email: this.profile.Email2,
          TcNo: null,
          Phone: this.profile.Phone2,
          NotificationType: parseInt(lastNotificationTypes)

        }
      }
      this.httpService.post("v1/SednaMobileB2C/UpdateGuestProfileFromPms", updateProfileObject).then(updatedProfile => {
        if (updatedProfile.ResponseType == "Ok") {
          this.translate.get("toastTranslations.loginUpdateOK").subscribe(res => {
            this.toast.toast(res, "success", 2000)
            this.storageService.removeItem("profile");
            this.storageService.setItem("profile", updatedProfile.Response)
            this.profile = updatedProfile.Response
            if (this.profile.Email2 == null || this.profile.Email2 == undefined || this.profile.Email2 == "") {
              if (this.profile.email != null || this.profile.email != undefined || this.profile.email != "") {
                this.profile.Email2 = this.profile.email
              }
            }
            this.profile.checkinDate = this.systemService.dateConverterFromDaysToYear(this.profile.checkinDate)
            this.profile.checkoutDate = this.systemService.dateConverterFromDaysToYear(this.profile.checkoutDate)
            //this.dismiss()
            // res.Email2 = this.profile.Email2
            // res.Phone2 = this.profile.Phone2
            // res.NotificationType = parseInt(lastNotificationTypes)
            // this.storageService.removeItem("profile");
            // this.storageService.setItem("profile", res)
            // this.storageService.getItem("profile").then(res=>{
            //   this.profile = res
            //   this.profile.checkinDate = this.systemService.dateConverterFromDaysToYear(this.profile.checkinDate)
            //   this.profile.checkoutDate = this.systemService.dateConverterFromDaysToYear(this.profile.checkoutDate)
            //   this.dismiss()
            // }).catch(err=>{
            //   console.log("hatalıysam ara 05375251193")
            // })
          })
        } else {
          this.translate.get("toastTranslations.loginUpdateFAIL").subscribe(res => {
            this.toast.toast(res, "fail", 2000)
          })
        }
      }).catch(err => {
        alert('alert: ' + JSON.stringify(err))
      })
    }
  }
  createQR() {
    this.qrVALUE = {
      name: this.profile.firstname,
      surname: this.profile.lastname,
      reservationId: this.profile.reservationId,
      guestId: this.profile.guestId,
      checkInDate: this.profile.checkinDate,
      checkOutDate: this.profile.checkoutDate
    }
    this.qrVALUE = JSON.stringify(this.qrVALUE)
    // "Adınız:"+this.qrVALUE.name+"\n Soyadınız:"+this.qrVALUE.surname;
  }
  loginParametersShow(check: string): void {
    switch (check) {
      case 'FirstName':
        this.firstNameCheck = 1;
        this.createFormControl("FirstName")
        break;
      case 'LastName':
        this.lastNameCheck = 1;
        this.createFormControl("LastName")
        break;
      case 'Email':
        this.emailCheck = 1;
        this.createFormControl("Email")
        break;
      case 'Voucher':
        this.voucherCheck = 1;
        this.createFormControl("Voucher")
        break;
      case 'Room':
        this.roomCheck = 1;
        this.createFormControl("Room")
        break;
      case 'Operator':
        this.operatorCheck = 1;
        this.createFormControl("Operator")
        break;
      case 'TcNo':
        this.tcNoCheck = 1;
        this.createFormControl("TcNo")
        break;
      case 'PassportNo':
        this.pasaportCheck = 1;
        this.createFormControl("PassportNo")
        break;
      // case 'PassportCheckBox':
      //   this.passportCheckBoxCheck = 1;
      //   this.createFormControl("PassportCheckBox")
      //   break;
      // case 'TcCheckBox':
      //   this.tcCheckBoxCheck = 1;
      //   this.createFormControl("TcCheckBox")
      //   break;
      case 'PassOrTc':
        this.PassOrTcCheck = 1;
        this.createFormControl("PassOrTc")
        break;
      // case 'Toggle':
      //   this.toggleCheck = 1;
      //   this.createFormControl("Toggle")
      //   break;
      case 'ReservationId':
        this.reservationIDCheck = 1;
        this.createFormControl("ReservationId")
        break;
      case 'GuestId':
        this.guestIDCheck = 1;
        this.createFormControl("GuestId")
        break;
      case 'DateOfBirth':
        this.birthdateCheck = 1;
        this.createFormControl("DateOfBirth")
        break;
      // case 'TcOrPassport':
      //   this.tcNoOrPassCheck = 1;
      //   this.createFormControl('TcOrPassport')
      //   break;

      default:
        break;
    }
  }

  logout() {
    console.log("logout çalıştı");
    this.storageService.removeItem("profile");
    this.storageService.removeItem("reservationId");
    this.storageService.removeItem("guestId");
    this.storageService.removeItem("confirmed");
    this.storageService.setItem("isLogin", false)
    this.shared.updateIsLogin(false)
    this.profile = undefined;
    this.notificationTypes = []
    this.initializeNotificationTypes()
    this.qrVALUE = null
  }

  onFocus() {
    this.backButtonVisibility = true
    this.shared.updateBottomTabBarVisibility(this.backButtonVisibility);
  }
  outFocus() {
    this.backButtonVisibility = false
    this.shared.updateBottomTabBarVisibility(this.backButtonVisibility);
  }
  segmentChanged(ev: any) {
    this.LoginSegment = ev.detail.value
  }
  updateInfos() { }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    }).then(res => {
      this.storageService.getItem("profile").then(res => {
        if (res) {

        } else {
          if (window.history.length == 1) {
            this.storageService.getItem("hotelId").then(hotelid => {
              this.storageService.getItem("groupId").then(groupid => {
                this.router.navigateByUrl('/group-hotel-selection-page;HotelId=' + hotelid + ';GroupId=' + groupid + '')
              })
            })
          } else {
            this.location.back()
          }
        }
      }).catch(err => {
        if (window.history.length == 1) {
          this.storageService.getItem("hotelId").then(hotelid => {
            this.storageService.getItem("groupId").then(groupid => {
              this.router.navigateByUrl('/group-hotel-selection-page;HotelId=' + hotelid + ';GroupId=' + groupid + '')
            })
          })
        } else {
          this.location.back()
        }
      })
    })
  }

  handleCheckboxChangeTc(value: any) {
    if (value.detail.checked === true) {
      this.tcChecked = true;
      this.passportChecked = false;
      this.formGroup.patchValue({
        PassOrTcControl: ""
      });
      this.loginObject.PassportNo = "";
      console.log('TC : ' + this.tcChecked);
      console.log('Passport : ' + this.passportChecked);
    }
    else {
      this.tcChecked = false;
      this.passportChecked = true;
    }
  }

  handleCheckboxChangePass(value: any) {
    if (value.detail.checked === true) {
      this.passportChecked = true;
      this.tcChecked = false;
      this.formGroup.patchValue({
        PassOrTcControl: ""
      });
      this.loginObject.TcNo = "";
      console.log('Passport : ' + this.passportChecked);
      console.log('TC : ' + this.tcChecked);
    }
    else {
      this.passportChecked = false;
      this.tcChecked = true;
    }
  }


  // toggleNotifications(data) {
  //   // console.log(data)
  //   console.log("isRequired ?????? -> "+data.detail.checked)

  //   if (data.detail.checked === true) { // Pasaport ile giriş
  //     this.selectedOptionPassport = true;
  //     this.isRequiredPassport = true;
  //     this.isRequiredTc = false;
  //     this.validatorsPassport = this.isRequiredPassport ? [Validators.required] : [];
  //     this.validatorsTc = this.isRequiredTc ? [Validators.required] : [];
  //     for (var i = 0; i < this.loginTypes.length; i++) {
  //       this.loginParametersShow(this.loginTypes[i]);
  //     }
  //   }
  //   else {
  //     this.selectedOptionPassport = false; // TC ile giriş
  //     this.isRequiredTc = true;
  //     this.isRequiredPassport = false;
  //     this.validatorsTc = this.isRequiredTc ? [Validators.required] : [];
  //     this.validatorsPassport = this.isRequiredPassport ? [Validators.required] : [];
  //     for (var i = 0; i < this.loginTypes.length; i++) {
  //       this.loginParametersShow(this.loginTypes[i]);
  //     }
  //   }
  // }
}
